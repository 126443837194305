import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import Logo from '../../assets/images/logo-dark.png'
import WOW from 'wowjs'

const Navbar = () => {
    const location = useLocation()
    const path = location.pathname
    const [sticky, setSticky] = useState(false)
    const [search, setSearch] = useState(false)
    const [mobile, setmobile] = useState(false)

    const [menu, setmenu] = useState({})
    const [home, setHome] = useState(false)
    const [header, setheader] = useState(false)
    const [pages, setpages] = useState(false)
    const [service, setservice] = useState(false)
    const [project, setproject] = useState(false)
    const [devops, setDevops] = useState(false)
    const [cloud, setCloud] = useState(false)
    const [blog, setblog] = useState(false)
 
    const activeMenu = () => {
        if (path === "/" || path === "/home-02" || path === "/home-03") {
            setmenu({ home: true })
        } else if (path === "/about" || path === "/team") {
            setmenu({ pages: true })
        } else if (path === "/service-01" || path === "/CloudServices" || path === "qa-testing" || path === "/it-management" || path === "cyber-security" || path === "/it-consultant" || path === "/infrastructure-plan") {
            setmenu({ services: true })
        } else if (path === "/Projects" || path === "/project-details") {
            setmenu({ project: true })
        } else if (path === "/blog" || path === "/blog-details") {
            setmenu({ blog: true })
        } else if (path === "/Contact") {
            setmenu({ contact: true })
        } else {
            setmenu({ home: true })
        }
    }

    useEffect(() => {
        window.addEventListener("scroll", isSticky);
        return () => {
            window.removeEventListener("scroll", isSticky);
        };
    }, []);

    useEffect(() => {
        window.scroll(0, 0)
        new WOW.WOW({
            live: false
        }).init();
        activeMenu()
    }, [path])

    const isSticky = () => {
        const scrollTop = window.scrollY;
        scrollTop >= 141 ? setSticky(true) : setSticky(false);
    }
    let sidebarObj;
    // function closeClick() {
    //     sidebarObj.hide();
    // }
    const closeClick = (e) => {sidebarObj.hide();}
     function refreshPage() {
    window.location.reload(false);
  }
    return (
        <>
            <div className="topbar">
                <div className="container-fluid">
                    <p className="topbar__text">Welcome to SkyOps - Your DevOps and Cloud Partner</p>
                    <ul className="topbar__info">
                        <li>
                            <i className="fa fa-envelope"></i>
                            <Link to="mailto:info@skyops.i">info@skyops.io</Link>
                        </li>
                        <li>
                            <i className="fa fa-map-marker"></i>
                            <Link to="https://www.google.com/maps/dir//Alpha+Techno+square.+Chaklala+Airport+Main+Terminal+Complex+Chaklala+Cantt.,+Rawalpindi,+Punjab+46000/@33.6062825,73.1007156,16z/data=!4m8!4m7!1m0!1m5!1m1!1s0x38dfebf43faab755:0x1d770c634cb7e8ff!2m2!1d73.1007156!2d33.6062825?entry=ttu">Alpha Techno Square, Old Airport</Link>
                        </li>
                    </ul>
                    <ul className="topbar__social">
                        <li><Link to="https://www.linkedin.com/company/skyops-io/"><i className="fab fa-linkedin" ></i></Link></li>
                    </ul>
                </div>
            </div>
            <nav className={`main-menu sticky-header ${sticky && "sticky-header--cloned sticky-fixed"}`}>
                <div className="container-fluid">
                    <div className="main-menu__logo">
                        <Link to="/" style={{ display: "flex", "alignItems": "center", "gap":"10px" }}>
                            <img src="assets/images/logo-cropped.png" width="80" alt="SkyOps" style={{ "objectFit": "cover" }} />
                            <strong style={{fontSize:"18px"}}>SkyOps</strong>
                        </Link>
                    </div>
                    <ul className="main-menu__list">
                        <li className={`menu-item-has-children ${menu.home && "current"}`}>
                            <Link to="/">Home</Link>   
                        </li>
                       
                        <li className={`menu-item-has-children ${menu.pages && "current"}`}>
                            <Link to="/About">About Us</Link>
                           {/* <ul>
                                <li><Link to="/team">Our Team</Link></li>
                            </ul>*/}
                        </li>
                        <li className={`menu-item-has-children ${menu.services && "current"}`}>
                            <Link to="#">Services</Link>
                            <ul>
                            <li><Link to="/DevOpsServices ">DevOps Consultancy</Link>
                                <ul className="submenu">
                                    <li><Link to="/IaC">IaC Consultancy</Link></li>
                                    <li><Link to="/CICDPipeline">CI/CD Pipeline </Link></li>
                                    <li><Link to="/ContainerOrchestration">Container Orchestration</Link></li>
                                </ul>
                            </li>
                                <li><Link to="/CloudServices">Cloud Consultancy</Link>
                                    <ul className="submenu">
                                        <li><Link to="/AWS">AWS Cloud</Link></li>
                                        <li><Link to="/GCP">Google Cloud </Link></li>
                                        <li><Link to="/Azure">Azure Cloud</Link></li>
                                        <li><Link to="/CostOptimization">Cost Optimization</Link></li>
                                        <li><Link to="/CloudMigration">Cloud Migration </Link></li>
                                        <li><Link to="/CloudSecurity">Cloud Security </Link></li>
                                        
                                    </ul>
                                </li>
                                
                            </ul>
                        </li>
                        <li className={`menu-item-has-children ${menu.project && "current"}`}>
                            <Link to="/Projects">Projects</Link>
                           
                        </li>
                        
                        <li className={`menu-item-has-children ${menu.contact && "current"}`}>
                            <Link to="/Contact">Contact</Link>
                        </li>
                    </ul>

                    <div className="main-menu__right">
                        <Link to="#S" className="mobile-nav__toggler" onClick={() => setmobile(true)}>
                            <span></span>
                            <span></span>
                            <span></span>
                        </Link>
                       
                        <Link to="/Contact" className="main-menu__cta">
                            <i className="fas fa-chalkboard-teacher" style={{fontSize:"24px"}}></i>
                            <span className="main-menu__cta__text">
                                <b>Book an Appointment</b>
                              
                            </span>
                        </Link>
                    </div>

                </div>
            </nav>
            <div className={`search-popup ${search && "active"}`}>
                <div className="search-popup__overlay search-toggler" onClick={() => setSearch(false)}></div>
                <div className="search-popup__content">
                    <form action="#">
                        <label htmlFor="search" className="sr-only">search here</label>
                        <input type="text" id="search" placeholder="Search Here..." />
                        <button type="submit" aria-label="search submit" className="thm-btn">
                            <span><i className="icon-magnifying-glass"></i></span>
                        </button>
                    </form>
                </div>
            </div>
            <div className={`mobile-nav__wrapper ${mobile && "expanded"}`}>
                <div className="mobile-nav__overlay mobile-nav__toggler" onClick={() => setmobile(false)}></div>
                <div className="mobile-nav__content">
                    <Link to="#" onClick={() => setmobile(false)} className="mobile-nav__close mobile-nav__toggler">
                        <span></span>
                        <span></span>
                    </Link>

                    <div className="logo-box">
                    <Link to="/" style={{ display: "flex", "alignItems": "center", "gap":"10px" }} onClick={closeClick}>
                            <img src="assets/images/logo-cropped.png" width="80" alt="SkyOps" style={{ "objectFit": "cover" }} />
                            <strong style={{fontSize:"18px"}}>SkyOps</strong>
                        </Link>
                    </div>
                    <div className="mobile-nav__container"><ul className="mobile-menu__list">
                        <li className={`menu-item-has-children ${menu.home ? "current" : ""}`}>
                            <Link to="/" className={home ? "expanded" : ""} onClick={closeClick}>Home</Link>
                        
                        </li>
                        <li className={`menu-item-has-children ${menu.pages ? "current" : ""}`}>
                            <Link to="/About" className={pages ? "expanded" : ""} onClick={closeClick}>About Us</Link>
                            
                        </li>
                        <li className={`menu-item-has-children ${menu.service ? "current" : ""}`}>
                            <Link to="#" className={service ? "expanded" : ""}>Services<button aria-label="dropdown toggler" onClick={() => setservice(!service)} className={service ? "expanded" : ""}><i className="fa fa-angle-down"></i></button></Link>
                            {service &&
                                <ul style={{ display: "block" }}>
                                <li className={`menu-item-has-children ${menu.devops ? "current" : ""}`}>
                            <Link to="/DevOpsServices" className={devops ? "expanded" : ""}>DevOps Consultancy<button aria-label="dropdown toggler" onClick={() => setDevops(!devops)} className={devops ? "expanded" : ""}><i className="fa fa-angle-down"></i></button></Link>
                            {devops &&
                                <ul style={{ display: "block" }}>
                                <li><Link to="/IaC" onClick={closeClick}>IaC Consultancy</Link></li>
                                    <li><Link to="/CICDPipeline" onClick={closeClick}>CI/CD Pipeline </Link></li>
                                    <li><Link to="/ContainerOrchestration" onClick={closeClick}>Container Orchestration</Link></li>
                                </ul>}
                        </li>

                        <li className={`menu-item-has-children ${menu.cloud ? "current" : ""}`}>
                            <Link to="/CloudServices" className={cloud ? "expanded" : ""}>Cloud Consultancy<button aria-label="dropdown toggler" onClick={() => setCloud(!cloud)} className={cloud ? "expanded" : ""}><i className="fa fa-angle-down"></i></button></Link>
                            {cloud &&
                                <ul style={{ display: "block" }} >
                                        <li><Link to="/AWS" onClick={closeClick} >AWS Cloud</Link></li>
                                        <li><Link to="/GCP" onClick={closeClick}>Google Cloud </Link></li>
                                        <li><Link to="/Azure" onClick={closeClick}>Azure Cloud</Link></li>
                                        <li><Link to="/CostOptimization" onClick={closeClick}>Cost Optimization</Link></li>
                                        <li><Link to="/CloudMigration" onClick={closeClick}>Cloud Migration </Link></li>
                                        <li><Link to="/CloudSecurity" onClick={closeClick}>Cloud Security </Link></li>
                                        
                                </ul>}
                        </li>
                                </ul>}
                        </li>
                        <li className={`menu-item-has-children ${menu.project ? "current" : ""}`}>
                            <Link to="/Projects" className={project ? "expanded" : "" } onClick={(e) => (closeClick) (refreshPage) } >Projects</Link>
                            {/* <Link to="#" className={project ? "expanded" : ""}>Projects<button aria-label="dropdow(rn toggler" onClick={() => setproject(!project)} className={project ? "expanded" : ""}><i className="fa fa-angle-down"></i></button></Link> */}
                        </li>
                        
                        <li><Link to="/Contact" onClick={closeClick} >Contact</Link></li>
                    </ul></div>

                    <ul className="mobile-nav__contact list-unstyled">
                        <li>
                            <i className="fa fa-phone"></i>
                            <a href="tel:+923325533938">+92 (332) 5533938</a>
                        </li>
                        <li>
                            <i className="fa fa-envelope"></i>
                            <a href="mailto:info@skyops.io">info@skyops.io</a>
                        </li>
                        <li>
                            <i className="fa fa-map-marker-alt"></i>
                            Alpha Techno Square, Old Airport<br /> Islamabad, Pakistan
                        </li>
                    </ul>
                    <ul className="mobile-nav__social">
                        <li><Link to="https://www.linkedin.com/company/skyops-io/"><i className="fab fa-linkedin"></i></Link></li>

                    </ul>
                </div>
            </div>
        </>
    )
}

export default Navbar