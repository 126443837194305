import React from 'react'
import { Link } from 'react-router-dom'
import BG2 from '../../assets/images/shapes/testi-bg-1-1.png'
import test01 from './icon.png'
import test02 from './profile.png'

function Peoples() {
    return (
        <>
            <section className="section-padding--bottom section-padding--top testimonials-two background-repeat-no background-position-top-center"
                style={{ backgroundImage: `url(${BG2})` }}>
                <div className="container">
                    <div className="row gutter-y-60">
                        <div className="col-lg-5">
                            <div className="testimonials-two__content">
                                <div className="section-title ">
                                    <p className="section-title__text">Owner</p>
                                    <h3 className="section-title__title">CEO Trusted Globally, CTO Innovates.</h3>
                                </div>
                                <div className="testimonials-two__content__text">With a global reputation built on trust, our CEO leads with integrity and vision, 
                                earning the confidence of people worldwide. Meanwhile, our CTO spearheads innovation, driving the company forward with cutting-edge 
                                solutions and a commitment to pushing technological boundaries. </div>
                                <div className="testimonials-two__content__text">Together, their leadership ensures a dynamic and trusted presence in the ever-evolving landscape of business and technology.</div>
                                <Link to="/About" className="thm-btn testimonials-two__content__btn"><span>View All
                                    feedbacks</span></Link>
                            </div>
                        </div>
                        <div className="col-lg-7">
                            <div className="testimonials-two__items">
                                <div className="row gutter-y-30">
                                    <div className="col-lg-12">
                                        <div className="testimonials-one-card">
                                            <div className="testimonials-one-card__image">
                                                <img src={test01} alt="DevOps" />
                                            </div>
                                            <div className="testimonials-one-card__content">
                                                <div className="testimonials-one-card__text" style={{textAlign:"justify", marginBottom:"12px"}}>Our CEO stands as the driving force behind our company's success, 
                                                embodying a vision that extends beyond boundaries. With a steadfast commitment to excellence, they steer the 
                                                organization towards new heights, fostering a culture of innovation and trust. </div>
                                                <strong className="testimonials-one-card__title" >Malik Najmus Siraj</strong>
                                                <p className="testimonials-one-card__designation">CEO</p>
                                                <i className="icon-right-quote testimonials-one-card__icon"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="testimonials-one-card">
                                            <div className="testimonials-one-card__image">
                                                <img src={test02} alt="DevOps" />
                                            </div>
                                            <div className="testimonials-one-card__content">
                                                <div className="testimonials-one-card__text" style={{textAlign:"justify", marginBottom:"12px"}}>As our Chief Technology Officer (CTO), 
                                                innovation is at the heart of their role. Leveraging an unparalleled understanding of technology trends, 
                                                our CTO drives the development of cutting-edge solutions that keep our company 
                                                at the forefront of the industry. </div>
                                                <strong className="testimonials-one-card__title">Nouman Iftikhar</strong>
                                                <p className="testimonials-one-card__designation">CTO</p>
                                                <i className="icon-right-quote testimonials-one-card__icon"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Peoples