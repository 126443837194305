import React from 'react'
import { Link } from 'react-router-dom'
import service_1 from './service_1.png'

function About() {
    return (
        <>
            <section className="section-padding--top section-padding--bottom about-one">
                <div className="container">
                    <div className="row gutter-y-60">
                        <div className="col-lg-6">
                            <div className="about-one__image">
                            <img src={service_1} className="wow fadeInUp" data-wow-duration="1500ms"alt="DevOps Expert" /> 
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="about-one__content">
                                <div className="section-title ">
                                    <p className="section-title__text">About Company</p>
                                    <h1 className="section-title__title">Experts of DevOps and
                                        DevSecOps</h1>
                                </div>
                                <div className="about-one__text">
                                SkyOps provides comprehensive DevOps services to streamline and enhance software development 
                                and operations. With a focus on efficiency and collaboration, SkyOps empowers organizations to 
                                accelerate their development cycles and improve overall product quality. 
                                </div>
                                <ul className="about-one__list">
                                    <li>
                                        <i className="fa fa-check-circle"></i>
                                        Continuous Integration and Deployment
                                    </li>
                                    <li>
                                        <i className="fa fa-check-circle"></i>
                                        Infrastructure as Code (IaC)
                                    </li>
                                    <li>
                                        <i className="fa fa-check-circle"></i>
                                        Container Orchestration
                                    </li>
                                </ul>
                                {/*<Link to="/about" className="thm-btn about-one__btn"><span>Learn More</span></Link>*/}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default About