import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay } from 'swiper/modules';
import './Clinttwo.css';
import 'swiper/css';
import 'swiper/css/pagination';

function Clinttwo() {
    const [showMap, setShowMap] = useState(false);
    const [mapSrc, setMapSrc] = useState("");

    const projectOptions = {
        loop: true,
        margin: 0,
        items: 1,

        breakpoints: {
            0: {
                slidesPerView: 1,
                spaceBetween: 30
            },
            768: {
                slidesPerView: 3,
                spaceBetween: 30
            },
            992: {
                slidesPerView: 4,
                spaceBetween: 30
            }
        },
        modules: [Navigation, Autoplay], // Add Navigation module
        navigation: true,
        autoplay: true
    }


    const handleImageClick = (mapLink) => {
        setMapSrc(mapLink);
        setShowMap(true);
    };

    const handleCloseMap = () => {
        setShowMap(false);
        setMapSrc(""); // Optionally reset the mapSrc
    };


    return (
        <>
            <div className="client-carousel client-carousel--two client-carousel--home-two" style={{ marginTop:"12px"}}>
            <p  className="about-three__list__title" style={{ textAlign: "center", marginTop:"12px" }} >Our Trusted Clients</p>
                <div className="container">
                    <Swiper className="thm-owl__carousel" {...projectOptions}>
                        <SwiperSlide className="item" style={{ textAlign: "center" }} onClick={() => handleImageClick("https://www.google.com/maps/d/embed?mid=1CrFYZv888U4N4mVyMm0H69zAfpz0tkY&ehbc=2E312F")}>
                            <div style={{ cursor: "pointer" }}>
                                <img src="assets/images/resources/RP.png" alt="Ripple Berry" />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide className="item" style={{ textAlign: "center" }} onClick={() => handleImageClick("https://www.google.com/maps/d/embed?mid=1CrFYZv888U4N4mVyMm0H69zAfpz0tkY&ehbc=2E312F")}>
                            <div style={{ cursor: "pointer" }}>
                                <img src="assets/images/resources/MT.png" alt="Musketeers Tech" />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide className="item" style={{ textAlign: "center" }} onClick={() => handleImageClick("https://www.google.com/maps/d/embed?mid=1CrFYZv888U4N4mVyMm0H69zAfpz0tkY&ehbc=2E312F")}>
                            <div style={{ cursor: "pointer" }}>
                                <img src="assets/images/resources/Raisin.png" alt="Raisin" />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide className="item" style={{ textAlign: "center" }} onClick={() => handleImageClick("https://www.google.com/maps/d/embed?mid=1CrFYZv888U4N4mVyMm0H69zAfpz0tkY&ehbc=2E312F")}>
                            <div style={{ cursor: "pointer" }}>
                                <img src="assets/images/resources/MN.png" alt="Made Neat" />
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </div>

            <CustomModal isOpen={showMap} close={handleCloseMap}>
                <iframe
                    src={mapSrc}
                    width="100%"
                    height="100%"
                    style={{ border: 0 }}
                    allowFullScreen=""
                    loading="lazy"
                ></iframe>
            </CustomModal>
        </>
    )
}

export default Clinttwo

const CustomModal = ({ isOpen, close, children }) => {
    if (!isOpen) return null;

    return (
        <div className="custom-modal">
            <div className="custom-modal-content">
                <button className="custom-modal-close" onClick={close}>&times;</button>
                {children}
            </div>
            <div className="custom-modal-backdrop" onClick={close} />
        </div>
    );
};



// const logoOptions = {
//     loop: true,
//     margin: 30,
//     items: 2,
//     module: [Navigation, Autoplay],
//     navigation: true,
//     autoplay: {
//         delay: 2500,
//         disableOnInteraction: false,
//     },
//     responsive: {
//         0: {
//             margin: 30,
//             items: 2
//         },
//         375: {
//             margin: 30,
//             items: 2
//         },
//         575: {
//             margin: 30,
//             items: 3
//         },
//         767: {
//             margin: 50,
//             items: 4
//         },
//         991: {
//             margin: 40,
//             items: 5
//         },
//         1199: {
//             margin: 80,
//             items: 5
//         },
//     }
// }
