import './App.css';
// import { BrowserRouter as Router } from "react-router-dom"
import React, { useEffect, useState } from 'react'
import './assets/vendors/bootstrap/css/bootstrap.min.css'
import './assets/vendors/fontawesome/css/all.min.css'
import './assets/vendors/jarallax/jarallax.css'
import './assets/vendors/animate/animate.min.css'
import './assets/vendors/owl-carousel/assets/owl.carousel.min.css'
import './assets/vendors/owl-carousel/assets/owl.theme.default.min.css'
import './assets/vendors/cretech-icons/style.css'
import './assets/vendors/youtube-popup/youtube-popup.css'
import './assets/css/cretech.css'
import {BrowserRouter as Router, Route,Routes, Navigate,} from 'react-router-dom';
import About from './components/About/Main'
import Contact from './components/Contact/Main'
import Footer from './components/Footer/Main'
import Home from './components/Home/Main'
import Navbar from './components/Navbar/Main'
import Projects from './components/Project/Projects/Main'
import CloudMigration from './components/Services/CM/Main'
import DevOpsServices from './components/Services/Services1/Main'
import CloudServices from './components/Services/Services2/Main'
import Pipeline from './components/Services/Pipeline/Main'
import IaC from './components/Services/Iaac/Main'
import AWS from './components/Services/AWS/Main'
import CostOptimization from './components/Services/CO/Main'
import CloudSecurity from './components/Services/CS/Main'
import GenerativeAI from './components/Project/ProjectDetails/Main'
import Ecommerce from './components/Project/Ecom/Main'
import LMS from './components/Project/LM/Main'
import Migration from './components/Project/Migration/Main'
import Saas from './components/Project/SaaS/Main'
import Web from './components/Project/website/Main'
import GCP from './components/Services/GC/Main'
import Azure from './components/Services/Azure/Main'
import ContainerOrchestration from './components/Services/Container/Main'
import { HelmetProvider } from 'react-helmet-async'
import ErrorPage from './components/Notfound/Errorpage'
import Showmaybe from './components/showmaybe/showmaybe';
import Services from './Services';

function App() {

  return (
    <HelmetProvider>
    <Router>  
    <Showmaybe>
    <Navbar/>
    </Showmaybe>
       <Routes>
        <Route exact path="/ErrorPage"  element={<ErrorPage />} />
        <Route path="*" element={<Navigate to="/ErrorPage" />}/>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/About" element={<About />} />
        <Route exact path="/Contact" element={<Contact />} />
        <Route exact path="/DevOpsServices" element={<DevOpsServices />} />
        <Route exact path="/CloudServices" element={<CloudServices />} />
        <Route exact path="/Iac" element={<IaC />} />
        <Route exact path="/CICDPipeline" element={<Pipeline />} />
        <Route exact path="/ContainerOrchestration" element={<ContainerOrchestration />} />
        <Route exact path="/AWS" element={<AWS/>}/>
        <Route exact path="/CostOptimization" element={<CostOptimization/>} />
        <Route exact path="/CloudMigration" element={<CloudMigration />} />
        <Route exact path="/CloudSecurity" element={<CloudSecurity />} />
        <Route exact path="/GCP" element={<GCP/>} />
        <Route exact path="/Azure" element={<Azure/>} />
        <Route exact path="/Projects" element={<Projects />} />
        <Route exact path="/GenerativeAI" element={<GenerativeAI />} />
        <Route exact path="/Ecommerce" element={<Ecommerce/>} />
        <Route exact path="/LMS" element={<LMS/>} />
        <Route exact path="/Migration" element={<Migration/>} />
        <Route exact path="/Saas" element={<Saas/>} />
        <Route exact path="/Web" element={<Web/>} />
      </Routes>
      <Showmaybe>
      <Footer />
      </Showmaybe>
    </Router>
    </HelmetProvider>
  );
}

export default App;
