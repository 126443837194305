import React, { useState, useEffect, useRef } from 'react';
import BGImg4 from '../../assets/images/shapes/funfact-one-bg.png'
import AnimatedNumber from "animated-number-react"

function Counter() {

    const [startAnimation, setStartAnimation] = useState(false);
    const numberRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                if (entries[0].isIntersecting) {
                    setStartAnimation(true);
                    observer.disconnect(); // Optional: Disconnect the observer if you only want to trigger once
                }
            },
            { threshold: 0.1 } // Trigger when 10% of the element is in view
        );

        if (numberRef.current) {
            observer.observe(numberRef.current);
        }

        return () => {
            if (numberRef.current) {
                observer.unobserve(numberRef.current);
            }
        };
    }, []);
    return (
        <>
            <section className="funfact-one">
                <div className="container">
                    <div className="funfact-one__inner wow fadeInUp background-size-cover" data-wow-duration="1500ms"
                        style={{ backgroundImage: `url(${BGImg4})` }}>
                        <div ref={numberRef}>
                            {startAnimation && (
                                <ul className="funfact-one__list">
                                    <li className="funfact-one__list__item">
                                        <strong className="funfact-one__list__title count-box" style={{fontSize:"48px", alignItems:"center"}}>
                                            <span data-stop="255" data-speed="2500" className="count-text"><AnimatedNumber value={23} duration={2500} formatValue={(v) => Math.round(v)} /></span>
                                        </strong>
                                        <p className="funfact-one__list__text">Total Clients</p>
                                    </li>
                                    <li className="funfact-one__list__item">
                                        <strong className="funfact-one__list__title count-box" style={{fontSize:"48px", alignItems:"center"}}>
                                            <span data-stop="325" data-speed="2500" className="count-text"><AnimatedNumber value={30} duration={2500} formatValue={(v) => Math.round(v)} /></span>
                                        </strong>
                                        <p className="funfact-one__list__text">Strategies Planned</p>
                                    </li>
                                    <li className="funfact-one__list__item">
                                        <strong className="funfact-one__list__title count-box" style={{fontSize:"48px", alignItems:"center"}}>
                                            <span data-stop="569" data-speed="2500" className="count-text"><AnimatedNumber value={54} duration={2500} formatValue={(v) => Math.round(v)} /></span>
                                        </strong>
                                        <p className="funfact-one__list__text">Projects Relased</p>
                                    </li>
                                    <li className="funfact-one__list__item">
                                        <strong className="funfact-one__list__title count-box" style={{fontSize:"48px", alignItems:"center"}}>
                                            <span data-stop="769" data-speed="2500" className="count-text"><AnimatedNumber value={50} duration={2500} formatValue={(v) => Math.round(v)} /></span>
                                        </strong>
                                        <p className="funfact-one__list__text">Satisfied Clients </p>
                                    </li>
                                </ul>
                            )}
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Counter