import React from 'react'
import { Link } from 'react-router-dom'
import resource from '../../assets/images/resources/about-four-1-1.jpg'
import resource01 from '../../assets/images/resources/about-four-author-1.jpg'
import { Helmet } from 'react-helmet-async'
function Company() {
    return (
        <>  
              <section className="about-four section-padding--top">
                <div className="container">
                    <div className="row gutter-y-60">
                        <div className="col-lg-6">
                            <div className="about-four__image">
                                <img src={resource} className="wow fadeInUp" data-wow-duration="1500ms" alt="DevOps" />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="about-four__content">
                                <div className="section-title ">
                                    <strong className="section-title__text">About Company</strong>
                                    <h1 className="section-title__title">Your DevOps Partner</h1>
                                </div>
                                <div className="about-four__text" style={{textAlign:"justify"}}>With a focus on seamless integration, agile methodologies, 
                                and cutting-edge solutions, we collaborate closely to optimize your operations, streamline 
                                development pipelines, and propel your business towards unparalleled efficiency and innovation. 
                                Trust us to navigate the complexities of DevOps, ensuring a tailored approach that aligns with 
                                your goals and drives sustainable growth. <br/> Our seasoned experts at Skyops combine industry best 
                                practices with innovative strategies, empowering your business to thrive in an ever-evolving digital ecosystem. 
                               </div>

                               {/* <div className="about-four__meta">
                                    <div className="about-four__author">
                                        <img src={resource01} alt="" />
                                        <div className="about-four__author__content">
                                            <h3 className="about-four__author__title">Malik Najmus Siraj</h3>
                                            <div className="about-four__author__designation">Founder CEO</div>
                                        </div>
                                    </div>
                                    <Link to="/About" className="thm-btn about-four__btn"><span>Learn More</span></Link>
                                </div>*/}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Company