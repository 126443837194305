import React from 'react'
import { Link } from 'react-router-dom'

function Benifit() {
  const services = [
    { title: "Quality Service", link: "/DevOpsServices ", icon: "icon-smart-tv", text: "Quality is the cornerstone of our services at SkyOps, where meticulous attention is given to details" },
    { title: "Expert Team", link: "/About", icon: "icon-link", text: "Our expert team at SkyOps is dedicated to providing cutting-edge solutions, staying at the forefront of industry trends." },
    { title: "Excellent Support", link: "/Contact", icon: "icon-technical-support", text: "Experience unparalleled support with SkyOps, where our commitment to excellence and perfection extends beyond implementation." },
    { title: "Management", link: "/About", icon: "icon-cctv", text: "Our robust management practices guarantee optimized processes, timely deliveries, and effective collaboration for your projects." },
  ];

  return (
    <>
      <section className="section-padding--bottom">
        <div className="container">
          <div className="section-title text-center">
            <p className="section-title__text">Company Benefits</p>
            <h2 className="section-title__title">We Provide Best DevOps Solutions and <br />DevSecOps</h2>
          </div>
          <div className="row gutter-y-30">
            {services.map(service => (
              <div key={service.title} className="col-lg-3 col-md-6 col-sm-12">
                <div className="service-card-two gray-bg">
                  <h3 className="service-card-two__title"><Link to={service.link}>{service.title}</Link></h3>
                  <p className="service-card-two__text">{service.text}</p>
                  <div className="service-card-two__icon">
                    <i className={service.icon}></i>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  )
}

export default Benifit
